// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/TopForm.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/TopForm.tsx");
  import.meta.hot.lastModified = "1715866721395.321";
}
// REMIX HMR END

import { validationAction } from "@/lib/utils";
import { AppContext } from "@/root";
import Search from "@/routes/search";
import { json, useNavigate } from "@remix-run/react";
import { useContext } from "react";
import { z } from "zod";
const formSchema = z.object({
  email: z.string().email({
    message: 'Please enter a valid email'
  }),
  phone: z.string().min(8, {
    message: 'Please enter a valid phone number'
  })
});
export default function TopFormContent({
  value
}) {
  _s();
  const navigate = useNavigate();
  const {
    email,
    phone,
    setErrors
  } = useContext(AppContext);
  console.log("🚀 ~ file: TopForm.tsx:5 ~ value:", value);
  const formData = new FormData();
  formData.append('phone', phone || '');
  formData.append('email', email || '');
  const handle = async () => {
    const {
      result,
      errors
    } = await validationAction({
      schema: formSchema,
      formData: formData
    });
    console.log("🚀 ~ file: TopForm.tsx:24 ~ errors:", errors);
    if (errors) {
      setErrors(errors);
      return json({
        errors
      }, {
        status: 400
      });
    }
    navigate(`/formdata/${value}`);
  };
  return <div className="mx-auto p-4 bg-sky-200 rounded-b-lg">
      <div className="text-sm font-medium m-4 flex items-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 9.97197C1 11.075 1.945 11.977 3.1 11.977H4.185M16 11H16.01M19.003 8.11397C19.378 8.47897 19.727 8.86897 19.851 9.47397C19.995 10.168 20.936 9.97197 21.475 9.97197C21.765 9.97197 22 10.196 22 10.473V13.482C22 14.612 17.8 14.666 17.8 16.299C17.8 17.236 18.376 20 16.75 20H15.299C14.381 20 14.08 18.911 13.745 18.272C13.446 17.701 10.604 17.701 10.305 18.272C9.97 18.912 9.669 20 8.75 20H7.3C5.756 20 6.25 17.606 6.25 16.698C6.24759 16.5623 6.19191 16.433 6.095 16.338C0.741 10.961 7.555 3.36297 15.14 6.69797C15.436 6.82797 15.814 6.69897 16.012 6.45297C16.466 5.89097 17.328 5.19797 18.328 5.00797C18.613 4.95397 18.85 5.18197 18.85 5.45897C18.85 6.08497 18.499 7.62297 19.003 8.11397Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <p className="ml-2">
          Søk hos over <span className="font-bold">30 banker</span> og velg det beste tilbudet.
        </p>
      </div>
      <div>
        <Search />
          <button className={!email || !phone ? "flex items-center justify-center bg-emerald-700 text-white font-bold py-2 px-4 mt-4 rounded-md cursor-not-allowed" : "flex items-center justify-center bg-emerald-700 text-white font-bold py-2 px-4 mt-4 rounded-md hover:bg-emerald-600"} onClick={handle} disabled={!email || !phone}>
            Gå videre
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
          marginLeft: "8px"
        }}>
              <path d="M4 12H20M20 12L16 8M20 12L16 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
      </div>



      <div className="flex my-5 flex-col sm:flex-row">
        <ul className="my-4 sm:w-1/2">
          <li className="text-black flex font-bold">
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.6665 12.304L8.57186 17.2041L8.96379 16.5188C11.0652 12.8444 13.9733 9.69492 17.469 7.3079L18.3332 6.71777" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span className="ml-2">Gratis</span>

          </li>
          <li className="text-black flex font-bold">
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.6665 12.304L8.57186 17.2041L8.96379 16.5188C11.0652 12.8444 13.9733 9.69492 17.469 7.3079L18.3332 6.71777" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span className="ml-2">Uforpliktende</span>
          </li>
          <li className="text-black flex">
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.6665 12.304L8.57186 17.2041L8.96379 16.5188C11.0652 12.8444 13.9733 9.69492 17.469 7.3079L18.3332 6.71777" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span className="ml-2">Søk raskt og enkelt</span>
          </li>
        </ul>

        <ul className="sm:ml-4 sm:mt-4 sm:w-1/2">
          <li className="text-black flex ">
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.6665 12.304L8.57186 17.2041L8.96379 16.5188C11.0652 12.8444 13.9733 9.69492 17.469 7.3079L18.3332 6.71777" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span className="ml-2">Raskt svar</span>

          </li>
          <li className="text-black flex">
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.6665 12.304L8.57186 17.2041L8.96379 16.5188C11.0652 12.8444 13.9733 9.69492 17.469 7.3079L18.3332 6.71777" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span className="ml-2">Gode betingelser</span>
          </li>
          <li className="text-black flex">
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.6665 12.304L8.57186 17.2041L8.96379 16.5188C11.0652 12.8444 13.9733 9.69492 17.469 7.3079L18.3332 6.71777" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span className="ml-2">Ingen skjulte kostnadder</span>
          </li>
        </ul>
      </div>

      <div className="w-full h-0.5 rounded-lg bg-white"></div>

      <p className="text-sm text-gray-500 mt-4 flex items-center">
        <span className="mr-2">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 17.4609V15.4609M12 15.4609C12.513 15.4609 12.9288 15.0132 12.9288 14.4609C12.9288 13.9087 12.513 13.4609 12 13.4609C11.487 13.4609 11.0712 13.9087 11.0712 14.4609C11.0712 15.0132 11.487 15.4609 12 15.4609ZM17.573 9.69958V9.46094C17.573 6.14723 15.0779 3.46094 12 3.46094C8.92209 3.46094 6.42696 6.14723 6.42696 9.46094V9.69958M17.573 9.69958C16.8871 9.46094 15.9654 9.46094 14.3028 9.46094H9.69722C8.03464 9.46094 7.11294 9.46094 6.42696 9.69958M17.573 9.69958C17.6567 9.7287 17.7369 9.76137 17.8146 9.79803C18.766 10.2475 19.4992 11.1053 19.8362 12.1635C20.089 12.9571 20.0115 13.9578 19.8566 15.9592C19.7232 17.6818 19.6566 18.543 19.3494 19.219C18.9398 20.1202 18.2305 20.8229 17.3623 21.1875C16.7111 21.4609 15.9083 21.4609 14.3028 21.4609H9.69722C8.09169 21.4609 7.28893 21.4609 6.63775 21.1875C5.7695 20.8229 5.06024 20.1202 4.65063 19.219C4.34342 18.543 4.27676 17.6818 4.14343 15.9592C3.98851 13.9578 3.91105 12.9571 4.16381 12.1635C4.50083 11.1053 5.234 10.2475 6.18545 9.79803C6.26305 9.76137 6.34325 9.7287 6.42696 9.69958" stroke="#2D373A" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
        Personvernet ditt ivaretas: <br /> Vi behandler alle data etter personvernforordningen (GDPR).
      </p>
    </div>;
}
_s(TopFormContent, "M+lpSzOl6PLBd0tQJHAEHkfDWmE=", false, function () {
  return [useNavigate];
});
_c = TopFormContent;
var _c;
$RefreshReg$(_c, "TopFormContent");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;